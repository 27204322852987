import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import deposits from "../../app/components/transaction/Deposits";
import css from "../css/Addcase.module.css";
import { Link } from 'react-router-dom';
//import Rightcontainer from "../Components/Rightcontainer";
import '../css/Loader.css'
import Swal from "sweetalert2";
// import findGif from "/";
import Header from "../Components/Header";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const Addcase = ({ walletUpdate }) => {

  const history = useHistory();
  let method = useRef();
  let checkInterval;
  const [userAllData, setUserAllData] = useState();


  const [global, setGlobal] = useState(100);
  const [next, setNext] = useState(1);
  const [process, setProcess] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const [isCashFreeActive, setCashFreeActive] = useState(false);
  const [isPhonePeActive, setPhonePeActive] = useState(false);
  const [isRazorPayActive, setRazorPayActive] = useState(false);
  const [isDecentroActive, setDecentroActive] = useState(false);
  const [isUpiGatewayActive, setUpiGatewayActive] = useState(false);
  const [upiIntent, setUpiIntent] = useState([]);

  const [isManualPaymentActive, setManualPaymentActive] = useState(false);
  const [isManualUPIQR, setManualUPIQR] = useState(true);

  const [RazorPayKey, setRazorPayKey] = useState(false);
  const [RazorPayAccountName, setAccountName] = useState('Skill Based Gaming');

  const [qrCode, setQrCode] = useState()
  const [walletOption, setWalletOption] = useState("airtel");
  const [bankCode, setBankCode] = useState(3003);

  const [account_mail_id, setAccount_mail_id] = useState();
  const [account_name, setAccount_name] = useState();
  const [accountPhone, setAccountPhone] = useState();

  const [scrnshot, setScrnshot] = useState(null);
  const [scrnshot1, setScrnshot1] = useState("");
  const [upiUtrNum, setupiUtrNum] = useState("");


  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]))
    setScrnshot(e.target.files[0])
  }

  const ManualPayment = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`,
    }
    const formData = new FormData();

    formData.append("Transaction_Screenshot", scrnshot);
    formData.append("amount", global);
    formData.append("referenceId", upiUtrNum);

    const response = await axios.post(
      baseUrl + `temp/deposite`,
      formData,
      { headers }
    );
    //console.log(response.data);
    if(response.data.status === 'Pending'){
                  history.push('/');

                Swal.fire({
            title: 'Deposit Transaction in proccess',
            icon: 'success',
            confirmButtonText: "OK",
          });

      setTimeout(() => {
     
      axios.get(baseUrl+`manual/depositstatus/${response.data._id}`, { headers })
        .then((res) => {
          //console.log(res);
          const icon = res.data.status === "PAID" ? "success" : "danger";
          var title = '';
          if(res.data && res.data.status === "PAID"){
            title = "Deposit submited successfully";
          }else if(res.data && res.data.status === "Pending"){
            title = "Deposit Transaction in proccess.";
          }else if(!res.data.status){
            title = "Deposit Transaction Rejected Due to invalid UTR Number.";
          }

          history.push('/');
          setTimeout(() => {
            setProcess(false);
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 1000);
        })
      }, 30000);
      setProcess(true);
    }else{
          setProcess(false);
          Swal.fire({
            title: 'Deposit Falied',
            icon: 'danger',
            confirmButtonText: "OK",
          });
      }
  }


  //Function to load razorpay script for the display of razorpay payment SDK.
  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  //function will get called when clicked on the pay button.
  async function displayRazorpayPaymentSdk(channel, method, upiMethod, razorpay) {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }
    //setProcess(true);
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // creating a new order and sending order ID to backend
    const response = await axios.post(baseUrl + `user/razorpay_order`, { amount: global, channel: channel, payment_method: method, provider: walletOption, bankCode: bankCode, account_name: account_name, payment_gatway: razorpay }, { headers });

    if (!response) {
      alert("Server error. please check are you onlin?");
      return;
    }

    //console.log(response.data.orderdata); 
    // Getting the order details back
    let order_id = response.data.txnID;
    let order_token = response.data.orderdata.id;
    const data = response.data.orderdata;
    const options = {
      key: RazorPayKey,
      name: RazorPayAccountName,
      description: "Skill Based Game Tournament",
      order_id: data.id,
      prefill: {
        "name": account_name,
        "email": account_mail_id,
        "contact": accountPhone
      },
      handler: async (response) => {
        //console.log(response)
        try {
          const paymentId = response.razorpay_payment_id;

          //const url = baseUrl+`rozarpay/capture/${paymentId}`;
          //const captureResponse = await axios.post(url, {},{headers})
          //console.log(captureResponse.data);
          checkrazorpaydeposit(order_id, order_token, 'SUCCESS', paymentId);
        } catch (err) {
          checkrazorpaydeposit(order_id, order_token, 'FAILED');
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const checkrazorpaydeposit = (order_id, order_token, order_status, paymentId) => {

    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl + `razorpaydesposit/response`, { order_id, order_token, order_status, paymentId }, { headers })
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title = res.data.status === "PAID" ? "Deposit submited successfully" : "Transaction Failed";
        history.push('/landing');
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);

      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
        }
      })
  }

  //use for decentrodepositeupi 
  const decentroDepositeUpi = (channel, method, upiMethod, decentropay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl + `user/decentrodepositeupi`,
      { amount: global, channel: channel, payment_method: method, provider: walletOption, bankCode: bankCode, account_name: account_name, payment_gatway: decentropay },
      { headers })
      .then((res) => {
        console.log(res);
        if (res.data.data.status === "SUCCESS") {

          let order_id = res.data.txnID;
          let order_token = res.data.data.decentroTxnId;
          //setProcess(false);
          window.open(res.data.data.data.generatedLink);
          setTimeout(() => {
            //checkdecentrodeposit(order_id, order_token)
            setProcess(false);
          }, 30000);

        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: 'danger',
            confirmButtonText: "OK",
          });
        }

      }).catch((e) => {
        alert(e)
      })
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  }



  //use for decentrodepositeupi 
  const phonePeDepositeUpi = (channel, method, upiMethod, phonepay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl + `user/phonedepositeapi`,
      { amount: global, channel: channel, payment_method: method, provider: walletOption, bankCode: bankCode, account_name: account_name, payment_gatway: phonepay },
      { headers })
      .then((res) => {
        // console.log(res.data.data.data.instrumentResponse.redirectInfo.url);
        // console.log(res.data.data.data.transactionId);
        // console.log(res.data.txnID);
        if (res.data.data.success === true) {

          let order_id = res.data.txnID;
          let order_token = res.data.data.data.transactionId;
          //setProcess(false);
          window.open(res.data.data.data.instrumentResponse.redirectInfo.url);
          setTimeout(() => {
            checkphonepedeposit(order_id, order_token)
            //history.push('/');
            setProcess(false);
          }, 30000);

        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: 'danger',
            confirmButtonText: "OK",
          });
        }

      }).catch((e) => {
        alert(e)
      })
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  }

  const checkphonepedeposit = (order_id, order_token) => {
    console.log(order_token);
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl + `phonepestatus/response`, { order_id, order_token }, { headers })
      .then((res) => {

        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title = res.data.status === "PAID" ? "Deposit submited successfully" : "Transaction in failed";

        if (res.data.status == 'Pending') {
          setTimeout(() => {
            checkphonepedeposit(order_id, order_token)
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 6000);
        } else {
          history.push('/');
          setTimeout(() => {
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 1000);
        }

      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
        }
      })
  }





  //use for upigatway 
 const [upiIntents,setupiIntents]=useState('');
 
  useEffect(() => {
    console.log(upiIntents);
  }, [upiIntents]);

  const depositUpiGateway = (channel, method, upiMethod, upigateway) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

  //  setProcess(true);
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl+`user/meghasoft/depositeupi`,
      { amount: global, channel: channel, payment_method: method, provider: walletOption, bankCode: bankCode, account_name: account_name, account_mail_id: account_mail_id, payment_gatway: upigateway },
      { headers })
      
 .then((res) => {
        if (res.data) {
         
         // let order_id = res.data.txnID;
         // let order_token = res.data.data.data.order_id;
      //    setupiIntents(res.data.data.data.upi_intent);
       //   console.log(upiIntents);
         // setUpiIntent(res.data.data.upi_intent);
         if(global>=10){
        //  window.open(res.data.url);
   }
          setTimeout(() => {
//            checkupideposit(order_id, order_token)
            setProcess(false);
          }, 30000);

        } else {
          setProcess(false);
          Swal.fire({
            title: "Failed",
            icon: 'danger',
            confirmButtonText: "OK",
          });
        }
      }).catch((e) => {
        alert(e)
      })
   
  }
const redirectToIntent=(link)=>{
    window.open(upiIntent[link]);
}
  const checkupideposit = (order_id, order_token) => {

    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    } 
    axios.post(baseUrl + `depositupipay/response`, { order_id, order_token }, { headers })
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title = res.data.status === "PAID" ? "Deposit submited successfully" : "Transaction Failed";
        history.push('/landing');
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);

      })
      .catch((e) => {
        if (e.response.status === 401) {
          // localStorage.removeItem('token');
          // localStorage.removeItem('token');
          window.location.reload()
        }
      })
  }
  
  //use for cashfree gatway
  const deposit = (channel, method, upiMethod) => {
    setProcess(true);
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl + `user/deposite`,
      { amount: global, channel: channel, payment_method: method, provider: walletOption, bankCode: bankCode },
      { headers })
      .then((res) => {
        if (res.data.data.payment_method === "app") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval((ID) => {
            checkout(ID);
          }, 10000, res.data.txnID);
        }
        else if (res.data.data.payment_method === "netbanking") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval((ID) => {
            checkout(ID);
          }, 10000, res.data.txnID);
        }
        else if (res.data.data.channel === 'link' && res.data.data.payment_method === "upi") {
          checkInterval = setInterval((ID) => {
            checkout(ID);
          }, 10000, res.data.txnID);
        //  window.location.href = res.data.data.data.payload[upiMethod];
        }
        else if (res.data.data.channel === 'qrcode' && res.data.data.payment_method === "upi") {
          setQrCode(res.data.data.data.payload.qrcode)
          setProcess(false)
          setNext(3);
          checkInterval = setInterval((ID) => {
            checkout(ID);
          }, 25000, res.data.txnID);
        }
      }).catch((e) => {
        alert(e)
      })
  }

  const checkout = (paymentID) => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl + `checkout/deposite/txn`,
      { txnID: paymentID },
      { headers })
      .then((res) => {
        // alert(res.data.txnStatus)
        if (res.data.txnStatus === 'PAID') {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: 'success',
            confirmButtonText: "OK",
          });
          setProcess(false);
          setNext(1)
        }
        else if (res.data.txnStatus === 'FAILED') {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: 'error',
            confirmButtonText: "OK",
          });
          setProcess(false);
          setNext(1);
        }
        // else if(res.data.txnStatus!="PENDING")
        // {
        //     clearInterval(checkInterval);
        //     Swal.fire({
        //       title: res.data.msg,
        //       icon: 'info',
        //       confirmButtonText: "OK",
        //   });
        // }

      }).catch((e) => {
        console.log(e)
      })
  }
      const [sitesetting, setsitesetting] = useState()

  const roledata = async () => {
    // const access_token = localStorage.getItem("token");
    await axios.get(baseUrl+`settings/data`).then((res) => {
    //   setUser(res.data._id);
      setsitesetting(res.data);
    //   userID.current=res.data._id;
    //   setMount(true);
    })
    .catch(e=>{
      if (e.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('token');
        window.location.reload()
        setTimeout(() => {
          //  history.push("/login")
        }, 500);
      }
      if (e.response.status === 400 || e.response.status === 429) {
        Swal.fire({
          title: 'Please refresh!',
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    })
  };

  useEffect(() => {
roledata();
    let access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        setAccount_mail_id(res.data.Email)
        setAccount_name(res.data.holder_name);
        setAccountPhone(res.data.Phone);
      }).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
          history.push("/login")
        }
      })

    axios.get(baseUrl + `website/setting`)
      .then((res) => {
        //console.log(res);
        setCashFreeActive(res.data.isCashFreeActive)
        setRazorPayActive(res.data.isRazorPayActive)
        setDecentroActive(res.data.isDecentroActive)
        setPhonePeActive(res.data.isPhonePeActive)
        setRazorPayKey(res.data.RazorPayKey)
        setAccountName(res.data.AccountName)
        setManualPaymentActive(true)
        setUpiGatewayActive(false)
        setManualUPIQR(true)
      }).catch((e) => {
        setCashFreeActive(false)
        setRazorPayActive(false)
        setDecentroActive(false)
        setPhonePeActive(false)
        setRazorPayKey(false)
        setManualPaymentActive(true)
        setManualUPIQR(false)
        setUpiGatewayActive(true)
        setAccountName('Skill Based Gaming')
      })


    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobile(true);
    }
  }, [])

  return (
    <>
      <Header user={userAllData} />
      {//userAllData && userAllData.verified === 'verified' && 
        <div className="leftContainer bg-white" style={{ minHeight: '100vh', height: '100%', paddingTop: '60px' }}>
          {Boolean(!process) && <div>
            {Boolean(next === 1) && <div className="px-4  py-5">
              <div className={`${css.games_section}`}>
                <div className="d-flex position-relative align-items-center">
                  <div className={`${css.games_section_title}`}>
                    Choose amount to add
                  </div>
                </div>
              </div>
              <div className="pb-3">
                <div className="MultiFormControl_root mt-4 MuiFormControl-fullWidth">
                  <div className="MuiFormControl_root MuiTextField-root">
                    <label
                      className={`${css.MuiFormLabel_root} MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink`}
                      data-shrink="true"
                      style={{ fontSize: "0.8rem", fontWeight: "400" }}
                    >
                      Enter Amount
                    </label>
                    <div className="MuiInputBase-root MuiInput-root MuiInput_underline jss58 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                      <div className="MuiInputAdornment-root MuiInputAdornment-positionStart d-flex w-100">
                        <div className="MuiInputAdornment-root MuiInputAdornment-positionStart d-flex align-items-center">
                          <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary mt-auto">₹</p>
                        </div>
                        <input
                          className={`w3-input input ${css.collapseCard_title} border-bottom text-dark`}
                          type="tel"
                          id="amountInput"
                          style={{ width: "100%", fontSize: '2em' }}
                          value={`${global}`}
                          onChange={(e) => { (e.target.value > 0) ? (e.target.value <= 25000) ? setGlobal(parseInt(e.target.value)) : (setGlobal(25000)) : (e.target.value < 0) ? setGlobal(10) : setGlobal(0) }}
                        ></input>
                      </div>
                    </div>
                    <p className="MuiFormHelperText-root">Min: 10, Max: 25000</p>
                  </div>
                </div>
                <div className={`${css.games_window}`}>
                  <div
                    className={`${css.gameCard_container}`}
                    onClick={() => {
                      // console.log(100);
                      setGlobal(100);
                    }}
                  >
                    <div className={`${css.add_fund_box}`}>
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          className={`${css.collapseCard_title} mr-1`}
                          style={{ fontSize: "0.9em" }}
                        >
                          ₹
                        </div>
                        <div
                          className={`${css.collapseCard_title}`}
                          style={{ fontSize: "1.5em" }}
                        >
                          100
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${css.gameCard_container}`}
                    onClick={() => {
                      // console.log(250);
                      setGlobal(250);
                    }}
                  >
                    <div className={`${css.add_fund_box}`}>
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          className={`${css.collapseCard_title} mr-1`}
                          style={{ fontSize: "0.9em" }}
                        >
                          ₹
                        </div>
                        <div
                          className={`${css.collapseCard_title}`}
                          style={{ fontSize: "1.5em" }}
                        >
                          250
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${css.gameCard_container}`}
                    onClick={() => {
                      // console.log(500);
                      setGlobal(500);
                    }}
                  >
                    <div className={`${css.add_fund_box}`}>
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          className={`${css.collapseCard_title} mr-1`}
                          style={{ fontSize: "0.9em" }}
                        >
                          ₹
                        </div>
                        <div
                          className={`${css.collapseCard_title}`}
                          style={{ fontSize: "1.5em" }}
                        >
                          500
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${css.gameCard_container}`}
                    onClick={() => {
                      // console.log(1000);
                      setGlobal(1000);
                    }}
                  >
                    <div className={`${css.add_fund_box}`}>
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          className={`${css.collapseCard_title} mr-1`}
                          style={{ fontSize: "0.9em" }}
                        >
                          ₹
                        </div>
                        <div
                          className={`${css.collapseCard_title}`}
                          style={{ fontSize: "1.5em" }}
                        >
                          1000
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${css.refer_footer}`}>
                <div className="d-grid gap-2 col-12 mx-auto">

                  <button type="button" className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`} onClick={() => { 
                   method.current = 'upipay'; depositUpiGateway("link","upi",'upipay','upigateway');
                  (global >= 10) ? setNext(2) : alert('Minimum deposit is 10') }}>Next</button>

                </div>
              </div>
            </div>}


            {Boolean(next === 2) && <div className="px-4 py-3">
              <div className="pb-3">
                <div className={`${css.games_section}`}>
                  <div className="d-flex position-relative align-items-center justify-content-between">
                    <div className={`${css.games_section_title}`} style={{ fontSize: '1.1em' }}>
                      Amount to be added ₹<span>{global}</span>
                    </div>
                    <button type="button" onClick={() => setNext(1)} className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1">Edit</button>
                  </div>
                </div>
              </div>
              <div style={{ "background": "rgb(241, 241, 241)", "width": "100%", "height": "10px", "position": "absolute", "left": "0", "right": "0" }}></div>
              <div className="d-flex flex-column mt-4">
                <div className="games-section-title">Pay Through UPI</div>

              { Boolean(isUpiGatewayActive) &&
              <div>
              <label htmlFor="username " className="mr-5">
                  <i className="far fa-bank mr-2"></i>Account holder name
                </label>
                <div className="col-12 mb-3 p-0">
                  <input
                    type="text"
                    className="form-control"
                    id="account_name"
                    placeholder="Enter Account Name"
                    name="acname"
                    value={account_name}
                    onChange={(e) => setAccount_name(e.target.value)}
                    required
                  />
                </div>

                <label htmlFor="username " className="mr-5">
                  <i className="far fa-bank mr-2"></i>Enter Your Mail ID
                </label>
                <div className="col-12 mb-3 p-0">
                  <input
                    type="text"
                    className="form-control"
                    id="account_mail_id"
                    placeholder="Enter Your Mail ID"
                    name="mailid"
                    value={account_mail_id}
                    onChange={(e) => setAccount_mail_id(e.target.value)}
                    required
                  />
                </div>
                  </div> }
                

              {Boolean(isUpiGatewayActive) &&
              <>
              <div
                    onClick={() => { upiIntents.bhim_link }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px", display: "flex", textAlign: "center" }}
                    >
                      <img
                        width="40px"
                        src="UPI.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingBottom: "10px",
                          paddingLeft: "3px",
                          paddingTop: "5px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Pay now QR</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                  </div>
                  
                </div> 
                
                                <div
                    onClick={() => { window.open(upiIntents.phonepe_link); }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px", display: "flex", textAlign: "center" }}
                    >
                      <img
                        width="40px"
                        src="phonepe-logo.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingBottom: "10px",
                          paddingLeft: "3px",
                          paddingTop: "5px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>PhonePe</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                  </div>
                  
                </div>


                <div
                    onClick={() => { window.open(upiIntents.paytm_link); }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px", display: "flex", textAlign: "center" }}
                    >
                      <img
                        width="40px"
                        src="paytm-logo.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingBottom: "10px",
                          paddingLeft: "3px",
                          paddingTop: "5px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Paytm</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                  </div>
                  
                </div>


                <div
                    onClick={() => { window.open(upiIntents.gpay_link); }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px", display: "flex", textAlign: "center" }}
                    >
                      <img
                        width="40px"
                        src="gpay-logo.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingBottom: "10px",
                          paddingLeft: "3px",
                          paddingTop: "5px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Google Pay</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                  </div>
                  
                </div>

                
              <div
                    onClick={() => {  setManualPaymentActive(true) }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px", display: "flex", textAlign: "center" }}
                    >
                      <img
                        width="40px"
                        src="UPI.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingBottom: "10px",
                          paddingLeft: "3px",
                          paddingTop: "5px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Pay now Instant</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                  </div>
                  
                </div> 
                  

                </>
                  
              }

                

                {Boolean(isRazorPayActive) &&
                  <div
                    onClick={() => { method.current = 'upipay'; displayRazorpayPaymentSdk("link", "upi", 'upipay', 'razorpay') }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #e0e0e0",
                        borderRadius: "7px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ height: "60px", display: "flex", textAlign: "center" }}
                      >
                        <img
                          width="40px"
                          src="UPI.png"
                          alt=""
                          style={{
                            marginLeft: "7px",
                            paddingBottom: "10px",
                            paddingLeft: "3px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="jss30">
                          <strong>Deposit Here</strong>
                        </div>
                        <div className="jss31"></div>
                      </div>
                    </div>

                  </div>}

                {Boolean(isPhonePeActive) &&
                  <div
                    onClick={() => { method.current = 'upipay'; phonePeDepositeUpi("link", "upi", 'upipay', 'Phonepe') }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #e0e0e0",
                        borderRadius: "7px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ height: "60px", display: "flex", textAlign: "center" }}
                      >
                        <img
                          width="40px"
                          src="UPI.png"
                          alt=""
                          style={{
                            marginLeft: "7px",
                            paddingBottom: "10px",
                            paddingLeft: "3px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="jss30">
                          <strong>Deposit PhonePe</strong>
                        </div>
                        <div className="jss31"></div>
                      </div>
                    </div>

                  </div>}

                {Boolean(isDecentroActive) &&
                  <div
                    onClick={() => { method.current = 'upipay'; decentroDepositeUpi("link", "upi", 'upipay', 'decentropay') }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #e0e0e0",
                        borderRadius: "7px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ height: "60px", display: "flex", textAlign: "center" }}
                      >
                        <img
                          width="40px"
                          src="UPI.png"
                          alt=""
                          style={{
                            marginLeft: "7px",
                            paddingBottom: "10px",
                            paddingLeft: "3px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column ml-4 mb-5">
                        <div className="jss30">
                          <strong>Desposit Now</strong>
                        </div>
                        <div className="jss31"></div>
                      </div>
                    </div>


                  </div>}


                  {Boolean(isManualPaymentActive) &&
                  <>
                  <div className="m-2">
                    <img
                      src={baseUrl + sitesetting.qrcode}
                      style={{ width: "100%", }} alt="img"
                    />
                    <div >
                    <center>
                    <p>{sitesetting.upiid}</p>
                    <button className={"btn btn-info"} onClick={()=>{ 
                        var upiIdInput =sitesetting.upiid;
        // upiIdInput.select();
        // upiIdInput.setSelectionRange(0, 99999); // For mobile devices

        navigator.clipboard.writeText(upiIdInput).then(function() {
//            alert('UPI ID copied to clipboard!');
                  Swal.fire({
            title: 'UPI ID copied ',
            icon: 'success',
            confirmButtonText: "OK",
          });


        }, function(err) {
            alert('Failed to copy UPI ID: ' + err);
        });
                    }}>Copy UPI ID</button></center>
                    </div>
                  </div>

                  <form
                    onSubmit={ManualPayment}
                    method="POST"
                    encType="multipart/form-data"
                  >

                <label htmlFor="username " className="mr-5">
                  <i className="far fa-bank mr-2"></i>Enter UTR Number
                </label>
                <div className="col-12 mb-3 p-0">
                  <input
                    type="text"
                    className="form-control"
                    id="referenceId"
                    placeholder="Enter UTR Number"
                    name="referenceId"
                    value={upiUtrNum}
                    onChange={(e) => setupiUtrNum(e.target.value)}
                    required
                  />
                </div>

                    <div className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                      <div className="d-flex align-items-center"
                        style={{
                          backgroundColor: "#fafafa",
                          border: "1px solid #e0e0e0",
                          borderRadius: "7px",
                        }} >

                        
                        <div className="d-flex align-items-center" style={{   textAlign: "center" }} >
                          <img width="40px"src="UPI.png"alt=""style={{marginLeft: "7px",paddingBottom: "10px",paddingLeft: "3px",paddingTop: "5px",}} />
                        </div>
                        <div className="d-flex justify-content-center flex-column ml-4">
                          <div className="jss30">
                            <strong>Upload Screenshot</strong>
                          </div>
                          <div className="jss31">
                            <input type="file" name="Screenshot" onChange={handleChange} accept="image/*"  />
                          </div>
                        </div>
                      </div>
                      
                    </div>

                    <div className=" m-2 text-center">
                      <button type="submit" className="btn btn-success">Submit</button>
                    </div>

                    <div style={{ width: "100%", height:"400px" }}>
                        <img src={scrnshot1} style={{ width: "100%" }} />
                      </div>
                  </form>
                      </>
                  }


                {Boolean(isMobile) && Boolean(isCashFreeActive) && <div>
                  <div
                    onClick={() => { method.current = 'gpay'; deposit("link", "upi", 'gpay') }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #e0e0e0",
                        borderRadius: "7px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ height: "60px", display: "flex", textAlign: "center" }}
                      >
                        <img
                          width="40px"
                          src="gpay-logo.png"
                          alt=""
                          style={{
                            marginLeft: "7px",
                            paddingBottom: "10px",
                            paddingLeft: "3px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="jss30">
                          <strong>G-Pay</strong>
                        </div>
                        <div className="jss31"></div>
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() => { method.current = 'phonepe'; deposit("link", "upi", "phonepe") }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #e0e0e0",
                        borderRadius: "7px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ height: "60px", display: "flex", textAlign: "center" }}
                      >
                        <img
                          width="40px"
                          src="/phonepe-logo.png"
                          alt=""
                          style={{
                            marginLeft: "7px",
                            paddingBottom: "10px",
                            paddingLeft: "3px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="jss30">
                          <strong>PhonePe</strong>
                        </div>
                        <div className="jss31"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => { method.current = 'paytm'; deposit("link", "upi", "paytm") }}
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #e0e0e0",
                        borderRadius: "7px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ height: "60px", display: "flex", textAlign: "center" }}
                      >
                        <img
                          width="40px"
                          src="/paytm-logo.png"
                          alt=""
                          style={{
                            marginLeft: "7px",
                            paddingBottom: "10px",
                            paddingLeft: "3px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="jss30">
                          <strong>Paytm UPI</strong>
                        </div>
                        <div className="jss31"></div>
                      </div>
                    </div>
                  </div>
                </div>}
                {Boolean(!isMobile) && Boolean(isCashFreeActive) && <div className="add-fund-box mt-3"
                  onClick={() => { deposit("qrcode", "upi") }}
                  style={{ paddingTop: "0px", height: "60px" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div className="d-flex align-items-center" style={{ height: "60px" }}>
                      <img
                        width="45px"
                        src="/qr-scan.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingLeft: "3px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Scan QR Code</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                  </div>
                </div>
                }
                {Boolean(isCashFreeActive) &&
                  <div className="games-section-title mt-3">Other Options</div>}

                {Boolean(isCashFreeActive) &&
                  <div className="add-fund-box mt-3" onClick={() => { setNext(4) }} style={{ paddingTop: "0px", height: "60px" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #e0e0e0",
                        borderRadius: "7px",
                      }}
                    >
                      <div className="d-flex align-items-center" style={{ height: "60px" }}>
                        <img
                          width="45px"
                          src="all-wallets.png"
                          alt=""
                          style={{
                            marginLeft: "7px",
                            paddingBottom: "10px",
                            paddingLeft: "3px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="jss30">
                          <strong>Other Wallets</strong>
                        </div>
                        <div className="jss31"></div>
                      </div>
                    </div>
                  </div>}
                {Boolean(isCashFreeActive) &&
                  <div className="add-fund-box mt-3" onClick={() => { setNext(5) }} style={{ paddingTop: "0px", height: "60px" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #e0e0e0",
                        borderRadius: "7px",
                      }}
                    >
                      <div className="d-flex align-items-center" style={{ height: "60px" }}>
                        <img
                          width="45px"
                          src="/bank1.png"
                          alt=""
                          style={{
                            marginLeft: "7px",
                            paddingBottom: "10px",
                            paddingLeft: "3px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="jss30">
                          <strong>Net Banking</strong>
                        </div>
                        <div className="jss31"></div>
                      </div>
                    </div>
                  </div>}

              </div>
            </div>}
          </div>}
          {Boolean(next === 3) && <div className="d-flex justify-content-center align-items-center mt-5">
            <img src={qrCode} alt="img" />
          </div>
          }
          {Boolean(next === 4) && <div className="px-4 py-3">
            <div className="pb-3">
              <div className={`${css.games_section}`}>
                <div className="d-flex position-relative align-items-center justify-content-between">
                  <div className={`${css.games_section_title}`} style={{ fontSize: '1.1em' }}>
                    Amount to be added ₹<span>{global}</span>
                  </div>
                  <button type="button" onClick={() => setNext(1)} className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1">Edit</button>
                </div>
              </div>
            </div>
            <div style={{ "background": "rgb(241, 241, 241)", "width": "100%", "height": "10px", "position": "absolute", "left": "0", "right": "0" }}></div>
            <div className="d-flex flex-column mt-4">
              <div className="games-section-title">Pay Through</div>
              <div className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                <div
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #e0e0e0",
                    borderRadius: "7px",
                  }}
                >
                  <div className="d-flex align-items-center" style={{ height: "60px" }}>
                    <img
                      width="45px"
                      src="all-wallets.png"
                      alt=""
                      style={{
                        marginLeft: "7px",
                        paddingLeft: "3px",
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center flex-column ml-4">
                    <div className="jss30">
                      <strong>Other Wallets</strong>
                    </div>
                    <div className="jss31"></div>
                  </div>
                  <div className="d-flex justify-content-center ml-auto mr-3">
                    <button type="button" onClick={() => setNext(2)} className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1">Edit</button>
                  </div>
                </div>
              </div>
            </div>
            <select className="form-control mt-4" style={{ "border": "1px solid rgb(224, 224, 224)", "outline": "none", "borderRadius": "5px", "background": "rgb(250, 250, 250)", "height": "3rem" }} onChange={(e) => { setWalletOption(e.target.value) }} >
              <option value="airtel">Airtel Money</option>
              <option value="freecharge">Freecharge</option>
              <option value="mobikwik">Mobikwik</option>
              <option value="ola">Ola Money</option>
              <option value="jio">Reliance Jio Money</option>
            </select>
            <div className={`${css.refer_footer}`}>
              <div className="d-grid gap-2 col-12 mx-auto">

                <button type="button" className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`} onClick={() => { deposit("link", "app") }}>Add</button>

              </div>
            </div>
          </div>
          }
          {Boolean(next === 5) && <div className="px-4 py-3">
            <div className="pb-3">
              <div className={`${css.games_section}`}>
                <div className="d-flex position-relative align-items-center justify-content-between">
                  <div className={`${css.games_section_title}`} style={{ fontSize: '1.1em' }}>
                    Amount to be added ₹<span>{global}</span>
                  </div>
                  <button type="button" onClick={() => setNext(1)} className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1">Edit</button>
                </div>
              </div>
            </div>
            <div style={{ "background": "rgb(241, 241, 241)", "width": "100%", "height": "10px", "position": "absolute", "left": "0", "right": "0" }}></div>
            <div className="d-flex flex-column mt-4">
              <div className="games-section-title">Pay Through</div>
              <div className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px" }}>
                <div
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #e0e0e0",
                    borderRadius: "7px",
                  }}
                >
                  <div className="d-flex align-items-center" style={{ height: "60px" }}>
                    <img
                      width="45px"
                      src="/bank1.png"
                      alt=""
                      style={{
                        marginLeft: "7px",
                        paddingLeft: "3px",
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center flex-column ml-4">
                    <div className="jss30">
                      <strong>Net Banking</strong>
                    </div>
                    <div className="jss31"></div>
                  </div>
                  <div className="d-flex justify-content-center ml-auto mr-3">
                    <button type="button" onClick={() => setNext(2)} className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1">Edit</button>
                  </div>
                </div>
              </div>
            </div>
            <select className="form-control mt-4" style={{ "border": "1px solid rgb(224, 224, 224)", "outline": "none", "borderRadius": "5px", "background": "rgb(250, 250, 250)", "height": "3rem" }} onChange={(e) => { setBankCode(e.target.value) }} >
<option value="3102">Jana Small Finance Bank</option>
            </select>
            <div className={`${css.refer_footer}`}>
              <div className="d-grid gap-2 col-12 mx-auto">

                <button type="button" className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`} onClick={() => { deposit("link", "netbanking") }}>Add</button>

              </div>
            </div>
          </div>
          }
          {Boolean(process) &&
            <div className="loaderReturn" style={{ zIndex: '99' }}>
              <img
                src={'https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif'}
                style={{ width: "100%", }} alt="img"
              />
            </div>
          }
        </div>}

    </>
  );
};
export default Addcase;